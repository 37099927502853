import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import logo from '../assets/images/logo.png';
import { logo, loadingIcon, SUPPORTED_FORMATS, ImageMaxSize ,handleGlobalLang} from '../constant/Util';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { addUpdateAPI, getAPI } from '../apiServices/ApiService'
import Swal from 'sweetalert2';
import Select, { components } from 'react-select';
import bg1 from '../assets/videos/login_bg.mp4';

function Register() {
    const [translate]=useState(handleGlobalLang(sessionStorage.getItem('translation') ? sessionStorage.getItem('translation'): "ENGLISH")); 
    const schema = yup.object().shape({
        // firstName: yup.string().required('First Name is required'),
        // lastName: yup.string().required('Last Name is required'),
        name: yup.string().required(translate?.t?.REGISTER?.NAME_IS_REQUIRED),
        email: yup.string()
            .required(translate?.t?.REGISTER?.EMAIL_IS_REQUIRED)
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                translate?.t?.REGISTER?.INVALID_EMAIL
            ),
        phone: yup.string().required(translate?.t?.REGISTER?.PHONE_NUMBER_IS_REQUIRED),
        // .length(10, translate?.t?.REGISTER?.PHONE_NUMBER_MUST_BE_10_DIGITS),
        phone_code: yup.string(),
        password: yup.string().required(translate?.t?.REGISTER?.PASSWORD_IS_REQUIRED)
            .min(6, translate?.t?.REGISTER?.PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS)
            .matches(/[A-Z]/, translate?.t?.REGISTER?.PWD_UPPER)
            .matches(/[a-z]/, translate?.t?.REGISTER?.PWD_LOWER)
            .matches(/\d/, translate?.t?.REGISTER?.PWD_NUM)
            .matches(/[!@#$%^&*(),.?":{}|<>]/, translate?.t?.REGISTER?.PWD_SPECIAL),
        confirmPassword: yup.string()
            .required(translate?.t?.REGISTER?.CONFIRM_PASSWORD_IS_REQUIRED)
            .test('password-match', translate?.t?.REGISTER?.PWD_MATCH, function (value) {
                const { password } = this.parent;
                return !password || value === password; // only check match if password is provided
            }),
        // .oneOf([yup.ref('password'), null], 'Passwords must match'),
        languages: yup.array().min(1, translate?.t?.REGISTER?.AT_LEAST_ONE_LANGUAGES_IS_REQUIRED).required(translate?.t?.REGISTER?.PREFERRED_LANGUAGE_IS_REQUIRED),
        profile: yup.mixed().notRequired().test(
            'fileType',
            translate?.t?.REGISTER?.IMAGE_FILES,
            value => {
                if (!value?.length) {
                    return true; // No file provided, so it's valid
                }
                return SUPPORTED_FORMATS?.includes(value?.[0]?.type);
            }
        ).test(
            'fileSize',
            `${translate?.t?.REGISTER?.IMAGE_SIZE} ${ImageMaxSize} MB`,
            value => {
                if (!value?.length) {
                    return true; // No file provided, so it's valid
                }
                return value?.[0]?.size < (ImageMaxSize * 1024 * 1024);
            }
        ),
    });    
    const navigate = useNavigate()
    const [view, setView] = useState(false);
    const [confirmView, setConfirmView] = useState(false);
    const [languages, setLanguages] = useState([]);
    const {
        register, control, handleSubmit, setValue, trigger, formState: { errors }, reset } = useForm({
            resolver: yupResolver(schema),
        });
    const [loading, setLoading] = useState(false)

    // Handle form submission
    useEffect(() => {
        // Fetch languages from the API
        getAPI('/language/?limit=0')
            .then(response => {
                const languageOptions = response.data.data.map((lang) => ({
                    value: lang.id,
                    label: lang.name,
                }))
                // setLanguages(response.data.data);
                setLanguages(languageOptions);
            })
            .catch(error => {
                console.error('Error fetching languages:', error);
            });
    }, []);

    const handlePhoneChange = (phone, country) => {
        let number = phone?.slice(country?.dialCode?.length)
        setValue('phone_code', country?.dialCode);
        setValue('phone', number);
        trigger('phone')
        // if (number?.length === 10) {
        //     trigger('phone')
        // }
    }
    const onSubmit = (data) => {
        setLoading(true)
        var apiData = {
            // "fullname": `${data?.firstName && data?.firstName} ${data?.lastName && data?.lastName}`,
            "fullname": data?.name,
            "email_id": data?.email,
            "mobile_no": data?.phone,
            "country_code": data?.phone_code,
            "password": data?.password,
            "preferred_language": data?.languages ? data?.languages?.map(lang => lang.value) : [],
        }
        const fd = new FormData()
        if (data?.profile?.[0]) {
            fd.append("profile", data?.profile?.[0])
        }
        fd.append("user_data", JSON.stringify(apiData));
        addUpdateAPI('POST', '/admin/register/', fd).then((res) => {
            if (res.data.status) {
                setLoading(false)
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: translate?.t?.REGISTER?.USER_REGISTERED_SUCCESSFULLY,
                    showConfirmButton: false,
                    timer: 2000
                })
                reset()
                navigate('/login')
            } else {
                setLoading(false)
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: res.data.detail,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        })
    };

    const customOptions = (props) => {
        return (
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />
                {" "}
                <label>{props.label}</label>
            </components.Option>
        );
    };


    return (
        <div className="container-fluid1 d-flex justify-content-center align-items-center vh-100 bg-image animationFadeIn">
            <div className='video-container'><video src={bg1} autoPlay muted loop className='bg-video' /></div>
            <div className="login-form bg-light px-5 py-3 rounded bg-transparent position-absolute">
                <div className='d-flex justify-content-center'>{logo('black')}</div>
                {/* <img src={logo} className="d-block mx-auto " alt="Logo" height={55} width={150} /> */}
                <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <input type='text' className='form-control form-control-sm' placeholder={translate?.t?.REGISTER?.NAME} aria-label='Name' {...register('name')} />
                            <span className="input-group-text" id="name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                </svg>
                            </span>
                        </div>
                        {errors.name && <p className="text-danger small">{errors.name.message}</p>}
                    </div>
                    <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" placeholder={translate?.t?.REGISTER?.EMAIL_ID} aria-label="Email ID" {...register('email')} />
                            <span className="input-group-text" id="Email ID">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                            </span>
                        </div>
                        {errors.email && <p className="text-danger small">{errors.email.message}</p>}
                    </div>
                    {/* <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" placeholder="First Name *" aria-label="First Name" {...register('firstName')} />
                            <span className="input-group-text" id="First Name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                </svg>
                            </span>
                        </div>
                        {errors.firstName && <p className="text-danger small">{errors.firstName.message}</p>}
                    </div>
                    <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" placeholder="Last Name *" aria-label="Last Name" {...register('lastName')} />
                            <span className="input-group-text" id="Last Name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                </svg>
                            </span>
                        </div>
                        {errors.lastName && <p className="text-danger small">{errors.lastName.message}</p>}
                    </div> */}
                    <div className='mb-3 mt-2'>
                        <label htmlFor='languages' className='form-label'>{translate?.t?.REGISTER?.PREFERRED_LANGUAGE}</label>
                        <Controller
                            name="languages"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={languages}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{ Option: customOptions }}
                                    onChange={val => { field.onChange(val); setValue('languages', val); trigger('languages'); }}
                                />
                            )}
                        />
                        {errors.languages && <p className="text-danger small">{errors.languages.message}</p>}
                    </div>
                    <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <PhoneInput
                                country={'in'}
                                enableSearch={true}
                                placeholder={translate?.t?.REGISTER?.PHONE_NO}
                                inputStyle={{ width: '100%', height: '30px' }}
                                onChange={(phone, country) => handlePhoneChange(phone, country)}
                            />
                        </div>
                        {errors.phone && <p className="text-danger small">{errors.phone.message}</p>}
                    </div>
                    <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <input type="file" className="form-control form-control-sm" placeholder={translate?.t?.REGISTER?.PROFILE} aria-label="profile" accept="image/*" {...register('profile')} />
                            <span className="input-group-text" id="profile">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-bounding-box" viewBox="0 0 16 16">
                                    <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                </svg>
                            </span>
                        </div>
                        {errors.profile && <p className="text-danger small">{errors.profile.message}</p>}
                    </div>
                    <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <input type={view ? "text" : "password"} className="form-control form-control-sm" autoComplete='new-password' placeholder={translate?.t?.REGISTER?.PASSWORD} aria-label="Password" {...register('password')} />
                            <span className="input-group-text" id="Password" onClick={() => setView(!view)} role='button'>
                                {view ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                    </svg>
                                )}
                            </span>
                        </div>
                        {errors.password && <p className="text-danger small">{errors.password.message}</p>}
                    </div>
                    <div className='mb-3 mt-2'>
                        <div className="input-group">
                            <input type={confirmView ? "text" : "password"} className="form-control form-control-sm" placeholder={translate?.t?.REGISTER?.CONFIRM_PASSWORD} aria-label="Confirm Password" {...register('confirmPassword')} />
                            <span className="input-group-text" id="Confirm Password" onClick={() => setConfirmView(!confirmView)} role='button'>
                                {confirmView ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                    </svg>
                                )}
                            </span>
                        </div>
                        {errors.confirmPassword && <p className="text-danger small">{errors.confirmPassword.message}</p>}
                    </div>
                    <div className='text-center'>
                        <Link className="btn btn-secondary rounded btn-sm " title='Back' to={'/login'}>{translate?.t?.REGISTER?.BACK}</Link>
                        {
                            loading ? <button type="submit" className="btn signInBtn rounded btn-sm ms-3" disabled title={translate?.t?.REGISTER?.REGISTER}>{loadingIcon} {translate?.t?.REGISTER?.REGISTER}</button>
                                :
                                <button type="submit" className="btn rounded btn-sm ms-3 signInBtn" title={translate?.t?.REGISTER?.REGISTER}>{translate?.t?.REGISTER?.REGISTER}</button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Register