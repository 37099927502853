import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import userProfile from '../assets/images/user-profile.png';
import { getAPI } from '../apiServices/ApiService';
import { logo, handleGlobalLang, profileName } from '../constant/Util';
import { playlistData } from '../layouts/DefaultLayout';
// import logoicon from '../assets/images/logo-icon.png';
import logoicon from '../assets/images/DBMusic.png'

function Header({ OpenMenu }) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  // const [alert, setAlert] = useState(false);
  const [language, setLanguage] = useState([]);
  const webSearchContent = useContext(playlistData)
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: webSearchContent?.language?.id || '',
    name: webSearchContent?.language?.name || '',
  }
  );
  const [search, setSearch] = useState();
  // const [searchCopy,setSearchCopy] = useState();
  const globalLanguage = ["ENGLISH", "ITALIA", "SPANISH", "FRENCH", "PORTUGUESE", "POLISH"];
  // const globalLanguage = ["EN", "IT", "ES", "FR", "PT", "PL"];
  const getCurrentUserDetails = () => {
    getAPI(`/admin/currentuser/`).then((res) => {
      sessionStorage.removeItem("profile_changed")
      setUserData(res?.data?.data);
    }).catch((err) => {
      console.log(err);
    })
  }
  const getLanguage = () => {
    getAPI('/language/?sort=true&limit=0').then((res) => {
      setLanguage(res?.data?.data);
    }).catch((err) => {
      console.log(err);
    })
  };
  useEffect(() => {
    const handleUserUpdated = (e) => {
      if (sessionStorage.getItem("token")) {
        getCurrentUserDetails();
      }
    };
    window.addEventListener("userUpdated", handleUserUpdated);
    return () => {
      window.removeEventListener("userUpdated", handleUserUpdated);
    };
  }, []);

  useEffect(() => {
    sessionStorage.getItem("token") && getCurrentUserDetails();
    getLanguage();
    // eslint-disable-next-line
  }, []);

  const handleLanguageChange = (languageId, languageName) => {

    if (languageId) {
      setSelectedLanguage({ id: languageId, name: languageName });
      webSearchContent?.setLanguage({ id: languageId, name: languageName });
    } else {
      setSelectedLanguage({
        id: '', name: ""
      });
      webSearchContent?.setLanguage({ id: '', name: '' });
    }
  }

  // useEffect(() => {
  //   if (alert) {
  //     swalMsg();
  //     setAlert(false)
  //   }
  // }, [alert])

  const isMobile = window.innerWidth < 768;

  const handleSubmit = (e) => {
    e.preventDefault();
    // if(searchCopy!==search){
    //   console.log(search,searchCopy);
    //   const event = new Event('searchUpdated');
    //   window.dispatchEvent(event);
    //   setSearchCopy(search)
    // }
    webSearchContent?.setSearch(search);
    search && navigate('/search')
  }
  useEffect(() => {
    if (!search) {
      webSearchContent?.setSearch('')
      // navigate('/home')
    }
    // eslint-disable-next-line
  }, [search])
  const handleClearLang = () => {
    webSearchContent?.setLanguage({ id: '', name: '' })
    setSelectedLanguage({ id: '', name: '' })
  }
  const handleTranslation = (item) => {
    sessionStorage?.setItem('translation', item)
    webSearchContent?.setTranslation(handleGlobalLang(item))
  }
  return (
    <div className={`web_header ${webSearchContent?.theme === 'light' ? 'web_headerLight' : 'web_headerDark'}`}>
      {
        isMobile ?
          <>
            <div className='p-2'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <svg className='me-3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={12} onClick={OpenMenu} role='button' fill={webSearchContent?.theme === 'light' ? "black" : "white"}>
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                  </svg>
                  <div className="navbar-brand text-center">
                    <img src={logoicon} alt="Logo" width="20" height="20" className="align-text-center me-1" />
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-end me-1'>
                  <div>
                    {
                      webSearchContent?.theme === "light" ?
                        <span className='mx-2' role='button' title={webSearchContent?.translation?.t?.HEADER?.SWITCH_TO_DARK_MODE} onClick={() => { webSearchContent?.setTheme('dark') }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={20} fill={webSearchContent?.theme === 'light' ? 'black' : 'white'} className="bi bi-moon-stars" viewBox="0 0 16 16">
                            <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278M4.858 1.311A7.27 7.27 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.32 7.32 0 0 0 5.205-2.162q-.506.063-1.029.063c-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286" />
                            <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                          </svg>
                        </span> :
                        <span role='button' title={webSearchContent?.translation?.t?.HEADER?.SWITCH_TO_LIGHT_MODE} onClick={() => { webSearchContent?.setTheme('light') }}><svg xmlns="http://www.w3.org/2000/svg" width={20} fill={webSearchContent?.theme === 'light' ? 'black' : 'white'} className="bi bi-sun-fill" viewBox="0 0 16 16">
                          <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                        </svg></span>
                    }
                  </div>
                  <div className="dropdown-center">
                    <button className={`btn btn-sm dropdown-toggle ${webSearchContent?.theme === 'dark' ? 'text-white' : 'text-dark'}`} type='button'
                      data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <small>{webSearchContent?.translation?.code}</small>
                  </button>
                  <ul className={`dropdown-menu dropdown-menu-end header_translate ${webSearchContent?.theme === 'dark' ? 'header_dropdownDark' : ''}`}>
                    {globalLanguage?.map((item, index) => (
                      <li key={index} className='p-2'>
                        <span className={`${webSearchContent?.theme === 'dark' ? 'dropdown-item' : 'dropdown-itemLight p-2'}`} key={index} onClick={() => { handleTranslation(item) }}>
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                  <div className="dropdown-center">
                    <button className={`btn btn-sm dropdown-toggle ${webSearchContent?.theme === 'dark' ? 'text-white' : 'text-dark'}`} type='button'
                      data-bs-toggle="dropdown"
                      aria-expanded="false"><small>{selectedLanguage.id ? selectedLanguage?.name?.substring(0, 2)?.toUpperCase() : "SE"} {selectedLanguage.id ? <span onClick={() => { handleClearLang() }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x mb-2" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg></span> : ""}</small></button>
                    <ul className={`dropdown-menu dropdown-menu-end header_dropdown ${webSearchContent?.theme === 'dark' ? 'header_dropdownDark' : ''}`}>
                      {language?.map((item, index) => (
                        <li key={index} className='p-2'>
                          <span className={`${webSearchContent?.theme === 'dark' ? 'dropdown-item' : 'dropdown-itemLight p-2'}`} key={item?.id} onClick={() => handleLanguageChange(item?.id, item.name)} value={item?.id}>
                            {item.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* {
                    sessionStorage.getItem('token') ?
                      <Link className="text-decoration-none" to='/upload' title='Upload Video/Audio'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='mx-3' width={22} role='button'>
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                        </svg>
                      </Link> :
                      <span className='d-flex align-items-center' title='Sign in to upload media'>
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setAlert(true)} viewBox="0 0 512 512" className='mx-3' width={22} role='button'>
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                        </svg>
                      </span>
                  } */}
                  {
                    sessionStorage.getItem('token') ?
                      <div className="">
                        <Link className="text-decoration-none" to={`/profile/${userData?.user_id}`}  >
                          {
                            userData?.profile ?
                              <img src={`${userData?.profile}?random=${Math.random().toFixed(2)}`} alt="user" width={25} height={25} style={{ borderRadius: '50%' }} /> :
                              userData?.fullname ?
                                <div className="d-flex align-items-center justify-content-center text-white " style={{ height: "25px", width: "25px", padding: "15px", backgroundColor: "#eb2163", fontSize: "13px", borderRadius: "50%", fontWeight: "bold" }} >{profileName(userData?.fullname)}</div>
                                : <img src={userProfile} alt="user" width={35} />
                          }
                        </Link>
                      </div>
                      :

                      <Link className={`btn ${webSearchContent?.theme === 'dark' ? "btn-outline-light signInBtnDark" : "signInBtn"} btn-sm`} to='/login'><small>{webSearchContent?.translation?.t?.HEADER?.SIGN_IN}</small></Link>
                  }
                </div>
              </div>
              <form className="input-group globalSearch mt-2"
                onSubmit={handleSubmit}
              >
                <input type="text" className={`form-control form-control-sm ${webSearchContent?.theme === 'light' ? '' : "globalsearchInput"}`} placeholder={webSearchContent?.translation?.t?.HEADER?.SEARCH} aria-label="Search" aria-describedby="button-addon2" value={search || ''} onChange={(e) => setSearch(e?.target?.value)} />
                <div className="input-group-append">
                  <button type='button' className={'btn-close ' + (webSearchContent?.theme === 'light' ? '' : " btn-close-white") + (search ? ' clear-search' : ' d-none')} style={{ zIndex: 99 }} onClick={() => { setSearch(''); webSearchContent?.setSearch(''); }}></button>
                  <button className={`btn px-3 searchIcon ${webSearchContent?.theme === 'dark' ? "" : "searchIconLight"}`} type="submit" id="button-addon2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={12} fill={webSearchContent?.theme === 'dark' ? 'white' : 'black'}>
                      <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </>
          :
          <nav className="navbar">
            <div className="container-fluid row">
              <div className='col-3 d-flex align-items-center justify-content-start'>
                <svg className='me-3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill={webSearchContent?.theme === 'light' ? 'black' : "white"} width={20} onClick={OpenMenu} role='button'>
                  <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
                {logo(webSearchContent?.theme === 'light' ? 'black' : "white")}
              </div>
              <div className="col-6 mx-auto mt-2 col-lg-5">
                <form className="input-group globalSearch"
                  onSubmit={handleSubmit}
                >
                  <input type="text" className={`form-control ${webSearchContent?.theme === 'light' ? '' : "globalsearchInput"}`} placeholder={webSearchContent?.translation?.t?.HEADER?.SEARCH} aria-label="Search" aria-describedby="button-addon2" value={search || ''} onChange={(e) => setSearch(e?.target?.value)} />
                  <div className="input-group-append">
                    <button type='button' className={'btn-close' + (webSearchContent?.theme === 'light' ? '' : " btn-close-white") + (search ? ' clear-search' : ' d-none')} style={{ zIndex: 99 }} onClick={() => { setSearch(''); webSearchContent?.setSearch(''); }}></button>
                    <button className={`btn px-3 searchIcon ${webSearchContent?.theme === 'dark' ? "" : "searchIconLight"}`} type="submit" id="button-addon2" >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16} fill={webSearchContent?.theme === 'light' ? 'black' : 'white'}>
                        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-3 d-flex align-items-center justify-content-end'>
                <div>
                  {
                    webSearchContent?.theme === "light" ?
                      <span className='mx-2' role='button' title={webSearchContent?.translation?.t?.HEADER?.SWITCH_TO_DARK_MODE} onClick={() => { webSearchContent?.setTheme('dark') }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} fill={webSearchContent?.theme === 'light' ? 'black' : 'white'} className="bi bi-moon-stars" viewBox="0 0 16 16">
                          <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278M4.858 1.311A7.27 7.27 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.32 7.32 0 0 0 5.205-2.162q-.506.063-1.029.063c-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286" />
                          <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                        </svg>
                      </span> :
                      <span className='mx-2' role='button' title={webSearchContent?.translation?.t?.HEADER?.SWITCH_TO_LIGHT_MODE} onClick={() => { webSearchContent?.setTheme('light') }}><svg xmlns="http://www.w3.org/2000/svg" width={20} fill={webSearchContent?.theme === 'light' ? 'black' : 'white'} className="bi bi-sun-fill" viewBox="0 0 16 16">
                        <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                      </svg></span>
                  }
                </div>
                {/* <div>
                {globalLanguage?.map((item, index) => (
                  <>
                  {index === 0 && <span className="mx-1 text-white">|</span>}
                  <span className={`me-2 ${webSearchContent?.theme === 'dark' ? 'text-white' : 'text-dark'}`} key={index} onClick={() => { handleTranslation(item) }}>
                  {item}
                </span>
                {index === globalLanguage?.length - 1 &&<span className="mx-1 text-white">|</span>}
                </>
                ))}
                </div> */}
                <div className="dropdown-center">
                  <button className={`btn btn-sm dropdown-toggle ${webSearchContent?.theme === 'dark' ? 'text-white' : 'text-dark'}`} type='button'
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {webSearchContent?.translation?.code}
                  </button>
                  <ul className={`dropdown-menu dropdown-menu-end header_dropdown ${webSearchContent?.theme === 'dark' ? 'header_dropdownDark' : ''}`}>
                    {globalLanguage?.map((item, index) => (
                      <li key={index} className='p-2'>
                        <span className={`${webSearchContent?.theme === 'dark' ? 'dropdown-item' : 'dropdown-itemLight p-2'}`} key={index} onClick={() => { handleTranslation(item) }}>
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="dropdown-center mx-2">
                  <button className={`btn btn-sm dropdown-toggle ${webSearchContent?.theme === 'dark' ? 'text-white' : 'text-dark'}`} type='button'
                    data-bs-toggle="dropdown"
                    aria-expanded="false">{selectedLanguage.id ? selectedLanguage?.name?.substring(0, 2)?.toUpperCase() : "SE"} {selectedLanguage.id ? <span onClick={() => { handleClearLang() }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x mb-2" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg></span> : ""}</button>
                  <ul className={`dropdown-menu dropdown-menu-end header_dropdown ${webSearchContent?.theme === 'dark' ? 'header_dropdownDark' : ''}`}>
                    {language?.map((item, index) => (
                      <li key={index} className='p-2'>
                        <span className={`${webSearchContent?.theme === 'dark' ? 'dropdown-item' : 'dropdown-itemLight p-2'}`} key={item?.id} onClick={() => handleLanguageChange(item?.id, item.name)} value={item?.id}>
                          {item.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* {
                  sessionStorage.getItem('token') ?
                    <Link className="text-decoration-none" to='/upload' title='Upload Video/Audio'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='mx-3' width={30} role='button'>
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                      </svg>
                    </Link> :
                    <span className='d-flex align-items-center' title='Sign in to upload media'>
                      <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setAlert(true)} viewBox="0 0 512 512" className='mx-3' width={30} role='button'>
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                      </svg>
                    </span>
                } */}
                {
                  sessionStorage.getItem('token') ?
                    <div className="mx-3">
                      <Link className="text-decoration-none" to={`/profile/${userData?.user_id}`}  >
                        {
                          userData?.profile ?
                            <img src={`${userData?.profile}?random=${Math.random().toFixed(2)}`} alt="user" width={25} height={25} style={{ borderRadius: '50%' }} /> :
                            userData?.fullname ?
                              <div className="d-flex align-items-center justify-content-center text-white " style={{ height: "25px", width: "25px", padding: "15px", backgroundColor: "#eb2163", fontSize: "13px", borderRadius: "50%", fontWeight: "bold" }} >{profileName(userData?.fullname)}</div>
                              : <img src={userProfile} alt="user" width={35} />
                        }
                      </Link>
                    </div>
                    :

                    <Link className={`btn ${webSearchContent?.theme === 'dark' ? "btn-outline-light signInBtnDark" : "signInBtn"} btn-sm`} to='/login'>{webSearchContent?.translation?.t?.HEADER?.SIGN_IN}</Link>
                }
              </div>
            </div>
          </nav>
      }
    </div>
  )
}

export default Header