import { getAPI } from "../apiServices/ApiService"
import logoicon from '../assets/images/DBMusic.png';
import Swal from "sweetalert2";
import EnglishTranslation from '../translate/EN.json'

export const logo = (color) => <div className="navbar-brand text-center d-flex align-items-center justify-content-center" style={{ fontFamily: 'auto', fontSize: 'medium' }} >
    <img src={logoicon} alt="Logo" width="34" height="35" className="align-text-center" />
    <h6 className="fw-bold mb-0 mt-2" style={{ color: color }}>DB Music </h6>
</div>

export const loadingIcon = <div className="spinner-border spinner-border-sm" role="status">
    <span className="visually-hidden">Loading...</span>
</div>

export const profileName = (name) => {
    let splits = name?.toUpperCase()?.split(' ');
    if (splits?.length > 1) {
        return splits[0][0] + splits[1][0];
    } else if (name?.length > 1) {
        return splits[0][0] + splits[0][1];
    }
}

export const tableStyle = {
    headRow: {
        style: {
            minHeight: '30px'
        }
    },
    headCells: {
        style: {
            fontSize: '12px',
            fontWeight: 'bold',
        },
    },
    rows: {
        style: {
            // marginTop: '10px',
            borderBottom: '1px solid lightgray',
            // boxShadow: "0px 3px 4px -5px grey",
            minHeight: '5px',
            padding: '3px',
            // backgroundColor: '#ffff',
            '&:hover': {
                boxShadow: "0px 1px 6px 2px #cfcfcf",
                cursor: 'pointer',
                backgroundColor: '#f5f5f5',
            },
        },
    },
    cells: {
        style: {
            fontSize: '12px',
        },
    },
    pagination:{
        style:{
            minHeight: '30px',
        }
    }
}
export const duration = (duration) => {
    if (duration > 60) {
        const minutes = Math.floor(duration / 60)
        const seconds = Math.floor(duration % 60)
        return `${minutes}:${seconds} mins`
    } else {
        return `${duration} secs`
    }

}
export const getUserRights = async () => {
    let rights = await getAPI('/admin/userrights/')
    return rights
}
export const checkRights = (rights, method) => {
    let check = false
    // eslint-disable-next-line
    rights?.map(it => {
        if (it?.feature_name === method && it?.status === true) {
            check = true
        }
    })
    return check
}

export const swalMsg = (msg) => {
    Swal.fire({
        animation: false,
        position: 'top-end',
        toast: true,
        icon: 'info',
        text: msg,
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
    })
}

export const extractYouTubeVideoIds = (url) => {
    // Regular expression pattern to match and capture YouTube video IDs
    const pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=))([\w-]{11}).*$/;
    const match = url?.match(pattern);
    if (match) {
        return match[1];
    } else {
        return null;
    }
}

export const timeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    const times = [
        { unit: 'year', seconds: 31536000 },
        { unit: 'month', seconds: 2592000 },
        { unit: 'week', seconds: 604800 },
        { unit: 'day', seconds: 86400 },
        { unit: 'hour', seconds: 3600 },
        { unit: 'minute', seconds: 60 },
        { unit: 'second', seconds: 1 },
    ];
    for (const { unit, seconds } of times) {
        const delta = Math.floor(diffInSeconds / seconds);
        if (delta >= 1) {
            return rtf.format(-delta, unit);
        }
    }
    return 'just now';
}

export const formatNumberValues = (num) => {
    if (num >= 1_000_000_000) {
        return (num / 1_000_000_000).toFixed(1) + 'B'; // Billion (1,00,00,00,000)
    } else if (num >= 10_000_000) {
        return (num / 10_000_000).toFixed(1) + 'M'; // Million (10,00,000)
    } else if (num >= 100_000) {
        return (num / 100_000).toFixed(1) + 'L'; // Lakh (1,00,000)
    } else if (num >= 1_000) {
        return (num / 1_000).toFixed(1) + 'K'; // Thousand (1,000)
    } else {
        return num; // Less than 1,000
    }
};

export const handleGlobalLang = (item) => {
    if (item?.toLowerCase() === "italia") {
        return { "code": "IT", "t": EnglishTranslation }
    } else if (item?.toLowerCase() === "french") {
        return { "code": "FR", "t": EnglishTranslation }
    } else if (item?.toLowerCase() === "polish") {
        return { "code": "PL", "t": EnglishTranslation }
    } else if (item?.toLowerCase() === "spanish") {
        return { "code": "ES", "t": EnglishTranslation }
    } else if (item?.toLowerCase() === "portuguese") {
        return { "code": "PT", "t": EnglishTranslation }
    } else {
        return { "code": "EN", "t": EnglishTranslation }
    }
}
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const SUPPORTED_VIDEO_FORMATS = ['video/mp4']

export const VideoMaxSize = 250;
export const VideoMinSize = 25;

export const AudioMaxSize = 50;
export const AudioMinSize = 5;

export const ImageMaxSize = 2;

// export const base_url= "http://172.105.54.28:8080";
export const base_url= "https://dbspotify.com:8080";
// export const base_url = "http://192.168.1.148:8080";
// export const base_url= "http://localhost:8000";
// export const base_url = "http://192.168.1.153:8000";
// export const base_url = "https://dbspotify.com:9000";